import * as React from 'react';
import axios from 'axios';
import EXIF from 'exif-js';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import { ExifData } from '../types/ExifData';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface SliderProps {
    images: string[];
}

const getExposureMode = (value: number): string => {
    switch (value) {
        case 0: return 'Auto exposure';
        case 1: return 'Manual exposure';
        case 2: return 'Auto bracket';
        default: return 'Unknown';
    }
}

export const Slider = ({ images }: SliderProps) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const [exifData, setExifData] = useState<ExifData | null>(null)

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  React.useEffect(() => {
    console.log(images[imageIndex]);
    axios
        .get(images[imageIndex], { responseType: 'arraybuffer' })
        .then(response => {
            const blob = new Blob([response.data]);
            // @ts-ignore
            EXIF.getData(blob, function() {
                // @ts-ignore
                const pixelXDimension = EXIF.getTag(this, 'PixelXDimension');
                // @ts-ignore
                const pixelYDimension = EXIF.getTag(this, 'PixelYDimension');
                // @ts-ignore
                const deviceModel = EXIF.getTag(this, 'Model');
                // @ts-ignore
                const focalLength = Number(EXIF.getTag(this, 'FocalLength'));
                // @ts-ignore
                const meteringMode = EXIF.getTag(this, 'MeteringMode');
                // @ts-ignore
                const fNumber = Number(EXIF.getTag(this, 'FNumber'));
                // @ts-ignore
                const exposureProgram = EXIF.getTag(this, 'ExposureProgram');
                // @ts-ignore
                const exposureTime = EXIF.getTag(this, 'ExposureTime');
                // @ts-ignore
                const exposureModeValue = EXIF.getTag(this, 'ExposureMode');
                // @ts-ignore
                const dateTime = EXIF.getTag(this, 'DateTime');
                // @ts-ignore
                const iso = EXIF.getTag(this, 'ISOSpeedRatings');

                const resolution = `${pixelXDimension} x ${pixelYDimension}`;

                const data: ExifData = {
                    resolution,
                    deviceModel,
                    focalLength: `${focalLength} mm`,
                    meteringMode,
                    fNumber: `f/${fNumber}`,
                    exposureProgram,
                    exposureTime: `${exposureTime.numerator}/${exposureTime.denominator}`,
                    exposureMode: getExposureMode(exposureModeValue),
                    dateTime,
                    iso
                }

                setExifData(data);
                console.log(data);
            })
        })
        .catch(console.error)
  }, [imageIndex]);

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <div className="next" onClick={() => paginate(1)}>
        {"‣"}
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        {"‣"}
      </div>
      <motion.div className='exif-data' drag>
        <p><b>Exposure time:</b> {exifData?.exposureTime}</p>
        <p><b>F Number:</b> {exifData?.fNumber}</p>
        <p><b>ISO:</b> {exifData?.iso}</p>
        <p><b>Focal length:</b> {exifData?.focalLength}</p>
        <p><b>Metering mode:</b> {exifData?.meteringMode}</p>
        <p><b>Resolution:</b> {exifData?.resolution}</p>
        <p><b>Exposure program:</b> {exifData?.exposureProgram}</p>
        {/* <p><b>Exposure mode:</b> {exifData?.exposureMode}</p> */}
        <p>{exifData?.dateTime}</p>
        <p>{exifData?.deviceModel}</p>
      </motion.div>
    </>
  );
}