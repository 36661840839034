import React from 'react';
import './App.css';
import { Slider } from './components/Slider';

function App() {
  const images = [
    '/assets/IMG_0061.JPG',
    '/assets/IMG_0116.JPG',
    '/assets/IMG_0138.JPG',
    '/assets/IMG_0139.JPG',
    '/assets/IMG_0140.JPG',
    '/assets/IMG_0160.JPG',
    '/assets/IMG_0164.JPG',
    '/assets/IMG_0194.JPG',
    '/assets/IMG_0197.JPG',
    '/assets/IMG_0212.JPG',
    '/assets/IMG_0253.JPG',
    '/assets/IMG_0260.JPG',
    '/assets/IMG_0388.JPG',
    '/assets/IMG_0390.JPG',
    '/assets/IMG_0393.JPG',
    '/assets/IMG_0417.JPG',
    '/assets/IMG_0465.JPG',
  ]

  return (
    <div className="App">
      <Slider images={images} />
    </div>
  );
}

export default App;
